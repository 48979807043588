import React, { useEffect } from "react";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection";
import "./roompage.css";
import VideoSection from "./VideoSection/VideoSection";
import ChatSection from "./ChatSection/ChatSection";
import RoomLabel from "./RoomLabel";
import { connect } from "react-redux";
import * as webRTCHandler from "./../utils/webRTCHandler";
import Overlay from "./Overlay";
import { setFromJid, setToJid, setCloseWindow } from "../store/actions";

//the  following code is for supporting enabling videochat directly from room page
import { useSearchParams } from "react-router-dom";

const Roompage = ({
  roomId,
  identity,
  isRoomHost,
  showOverlay,
  connectOnlyWithAudio,
  fromJid,
  toJid,
  setCloseWindowAction,
}) => {
  //new code to support direct landing of room page
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.get("roomid") === null ||
      searchParams.get("identity") === null
    ) {
      setCloseWindowAction(false);
      //  the code is new  code for supporting landing directly  to room page
      if (!isRoomHost && !roomId) {
        const siteUrl = window.location.origin;
        window.location.href = siteUrl;
      } else {
        localStorage.setItem("backrefresh", "1");
        webRTCHandler.getLocalPreviewAndInitRoomConnection(
          isRoomHost,
          identity,
          roomId,
          connectOnlyWithAudio,
          fromJid,
          toJid
        );
      }
    } else {
      // the following code is new code for  suuppporting directly landing to room
      setCloseWindowAction(true);
      const room = searchParams.get("roomid");
      const myName = searchParams.get("identity");
      const roomHost = false;
      console.log(` room ${room} fromJid ${fromJid} toJid ${toJid}`);
      localStorage.setItem("backrefresh", "1");
      webRTCHandler.getLocalPreviewAndInitRoomConnection(
        roomHost,
        myName,
        room,
        connectOnlyWithAudio,
        fromJid,
        toJid
      );
    }
  }, []);

  return (
    <div className="room_container">
      {/* <ParticipantsSection /> */}
      <VideoSection />
      {/* <ChatSection /> */}
      {/* <RoomLabel roomId={roomId} /> */}
      {showOverlay && <Overlay />}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setFromJidAction: (fromJid) => dispatch(setFromJid(fromJid)),
    setToJidAction: (toJid) => dispatch(setToJid(toJid)),
    setCloseWindowAction: (closeWindow) =>
      dispatch(setCloseWindow(closeWindow)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Roompage);
